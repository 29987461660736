import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { PhoneIcon, CheckIcon } from '@heroicons/react/20/solid'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import IframeResizer from 'iframe-resizer-react'
import { useMailChimp } from 'react-use-mailchimp-signup'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Menu from '../components/menu'
import Seo from '../components/seo'
import Wordmark from '../../assets/wordmark.svg'

const IndexPage = ({ active = `home` }) => {
  const [fname, setFname] = useState('')
  const [lname, setLname] = useState('')
  const [email, setEmail] = useState('')
  const [mode, setMode] = useState('')

  const url = `https://myworkandwellbeing.us14.list-manage.com/subscribe/post?u=801330bbccd95c9122597dad8&amp;id=62d7956e54&amp;f_id=003193e0f0`
  const { status, subscribe } = useMailChimp({
    action: url,
  })

  const handleClick = async (e) => {
    e.preventDefault()
    if (fname && lname && email) {
      subscribe({ fname, lname, email })
      setMode('submitted')
    } else setMode('bad')
  }

  return (
    <Layout>
      <Seo title="My Work and Well-Being | Home page" />
      <div className="max-w-[1400px] mx-auto">
        <div className="mx-auto px-6 py-6 lg:flex lg:items-baseline lg:justify-between lg:px-8 text-center h-auto">
          <Wordmark className="max-w-sm inline pb-14 lg:pb-0" />
          <Menu active={active} />
        </div>
      </div>
      <div className="max-w-[1400px] mx-auto text-center bg-slate-50 pt-1 rounded-t-lg">
        <StaticImage
          src="../images/hero.jpg"
          alt="Decorative image of a healthy workplace team"
          className="rounded-lg"
        />
      </div>
      <section className="py-24 md:py-36 max-w-[1400px] mx-auto bg-slate-50">
        <h3 className="text-myorange text-2xl md:text-3xl mb-4 text-center">
          Well-Being Strategy and Consultancy
        </h3>
        <h2 className="text-4xl md:text-5xl mb-6 text-center">
          Supporting Your Well-Being Goals
        </h2>
        <div className="mx-auto px-6 pt-6 lg:pt-12 lg:flex lg:items-baseline lg:justify-between lg:px-8 text-center h-auto max-w-5xl">
          <p className="text-xl md:text-2xl lg:basis-1/3 text-left text-gray-700">
            Our team of consultants will work with you to make sure your
            well-being strategy is successful, whether you are at a crucial
            review point or are just starting:
          </p>
          <div>
            <p className="text-left text-gray-600 mt-6 lg:mt-0">An overview:</p>
            <ul className="text-xl md:text-2xl lg:basis-1/2 text-left text-gray-600 tracking-tight font-tbold checks">
              <li>Review your current well-being plan</li>
              <li>Evaluate the success of your efforts</li>
              <li>Assess resiliency across your organization</li>
              <li>Make a customised well-being plan</li>
              <li>Create a series of focused projects</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="bg-gray-800 py-16 px-6">
        <h3 className="font-tbold text-myblue text-3xl lg:text-5xl text-center">
          Well-Being &amp; Productivity Driven Solutions
        </h3>
      </section>
      <section className="py-24 md:py-36 max-w-[1400px] mx-auto bg-slate-50">
        <div className="flex">
          <div className="max-w-xs md:max-w-lg px-8">
            <h3 className="text-myblue text-2xl md:text-3xl mb-8">
              Well-Being Strategy Development
            </h3>
            <h2 className="text-4xl md:text-5xl mb-12">
              Develop a Tailored Well-Being Strategy for Your Organization
            </h2>
            <p className="text-xl md:text-2xl mb-6 text-gray-800">
              A robust well-being strategy can breathe new life into your
              organization.
            </p>
            <p className="text-xl md:text-2xl mb-6 text-gray-800">
              We take a data-driven, whole-organization approach to developing
              tailored well-being strategies. It is a tried-and-tested process
              for creating resilient and engaged employees and a sustainable
              work environment that will support your business success.
            </p>
          </div>
          <div className="border-l-2 border-gray-300 pl-6">
            <div className="max-w-[1400px] overflow-hidden h-full text-center">
              <StaticImage
                src="../images/workers1.jpg"
                alt="Decorative image of a healthy workplace team"
                className="h-full"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-nowrap items-center justify-evenly p-6 my-16 bg-myred max-w-[1400px] mx-auto">
        <div className="flex items-center mr-6 text-white">
          <Cog6ToothIcon className="h-12 w-12" />{' '}
          <span className="text-2xl lg:text-3xl pl-2">
            Ready to Shift Gears and Take Action?
          </span>
        </div>
        <div className="flex items-center flex-shrink-0 mr-6 text-white font-tbold">
          <Link
            type="button"
            to="/bookcall"
            className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm text-myred shadow-sm hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PhoneIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Book a Call
          </Link>
        </div>
      </section>
      <section className="py-12 max-w-[1400px] mx-auto bg-white">
        <div className="md:max-w-4xl px-8">
          <h3 className="text-mypurple text-2xl md:text-3xl mb-8">
            Well-Being Assessment
          </h3>
          <h2 className="text-4xl md:text-5xl mb-12">
            Measure the Well-Being of Your Workforce
          </h2>
          <p className="text-xl md:text-2xl mb-6 text-gray-800">
            Robust benchmarks are the foundation for a successful well-being
            strategy.
          </p>
          <p className="text-xl md:text-2xl mb-6 text-gray-800">
            We give a scientifically validated assessment of well-being in an
            organization using resilience and well-being psychometrics.
          </p>
          <div>
            <p className="text-left text-gray-600">An overview:</p>
            <ul className="text-xl md:text-2xl lg:basis-1/2 text-left text-gray-600 tracking-tight font-tbold checks">
              <li>
                Assessing employees: Get an overview of the well-being and
                resilience of your workforce
              </li>
              <li>Analysis of physical, psychological, and social factors</li>
              <li>
                Feedback on the ways in which your managers and leaders promote
                a positive culture.
              </li>
              <li>
                Corporate Well-Being: Insight into the critical elements
                undermining the performance of individuals, teams, and the
                organization.
              </li>
              <li>
                Feedback from your staff and managers on how you may better
                support their performance and well-being.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section>
        <div className="max-w-[1400px] mx-auto my-12 text-center">
          <StaticImage
            src="../images/employeeWellness.jpg"
            alt="Decorative image depicting employee wellness"
            className="h-full"
          />
        </div>
      </section>
      <section className="py-24 md:py-36 max-w-[1400px] mx-auto bg-slate-50">
        <div className="flex">
          <div className="max-w-xs md:max-w-lg px-8">
            <h3 className="text-myorange text-2xl md:text-3xl mb-8">
              Well-being Strategy Evaluation
            </h3>
            <h2 className="text-4xl md:text-5xl mb-12">
              Measuring Progress and ROI
            </h2>
            <p className="text-xl md:text-2xl mb-6 text-gray-800">
              A well-being strategy cannot be created by checking boxes. You
              will be able to identify what is functioning well and where there
              is room for improvement with the help of a defined evaluation
              plan. We will hold a 6-month review meeting after your well-being
              strategy has been launched to assess impact, recognize
              accomplishments, and discuss lessons learned.
            </p>
          </div>
          <div className="border-l-2 border-gray-300 pl-6">
            <div className="max-w-[1400px] overflow-hidden h-full text-center">
              <StaticImage
                src="../images/workers2.jpg"
                alt="Decorative image of a healthy workplace team"
                className="h-full"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-nowrap items-center justify-evenly p-6 my-16 bg-mypurple max-w-[1400px] mx-auto">
        <div className="flex items-center mr-6 text-white">
          <Cog6ToothIcon className="h-12 w-12" />{' '}
          <span className="text-2xl lg:text-3xl pl-2">
            Ready to Shift Gears and Take Action?
          </span>
        </div>
        <div className="flex items-center flex-shrink-0 mr-6 text-white font-tbold">
          <Link
            type="button"
            to="/bookcall"
            className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm text-mypurple shadow-sm hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PhoneIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Book a Call
          </Link>
        </div>
      </section>
      <section className="py-12 max-w-[1400px] mx-auto">
        <div id="offer" className="flex flex-col md:flex-row">
          <div className="md:max-w-sm px-8">
            <h3 className="text-myred text-3xl mb-8">Free Offer</h3>
            <h2 className="text-5xl mb-4 md:mb-8">
              E-booklet on health &amp; well-being in the workplace
            </h2>
            <p className="text-xl md:text-2xl mb-6 text-gray-800">
              To receive this free offer, please provide your email to join our
              mailing list. We will only ever send occassional and always
              high-value resources like this one.
            </p>
            <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600 mb-2">
              <label
                htmlFor="name"
                className="block text-xs font-medium text-gray-900"
              >
                First Name
              </label>
              <input
                type="text"
                name="firstname"
                id="firstname"
                onChange={(e) => setFname(e.target.value)}
                className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="First Name"
              />
            </div>
            <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600 mb-2">
              <label
                htmlFor="name"
                className="block text-xs font-medium text-gray-900"
              >
                Last Name
              </label>
              <input
                type="text"
                name="lastname"
                id="lastname"
                onChange={(e) => setLname(e.target.value)}
                className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="Last Name"
              />
            </div>
            <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600 mb-4">
              <label
                htmlFor="email"
                className="block text-xs font-medium text-gray-900"
              >
                Business Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="Your email"
              />
            </div>
            <div className="flex items-center flex-shrink-0 mr-6 text-white font-tbold mb-12 md:mb-0">
              <button
                type="button"
                onClick={handleClick}
                className="inline-flex items-center gap-x-1.5 rounded-md bg-mypurple hover:bg-black px-2.5 py-1.5 text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <CheckIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Send me this resource
              </button>
            </div>
            <div className="flex items-center flex-shrink-0 mr-6 text-black font-tbold mt-4 md:mb-0">
              {mode === `bad` ? (
                <p>Please fill in all fields</p>
              ) : mode === `submitted` && status === `sending` ? (
                <p>Processing...</p>
              ) : mode === `submitted` && status === `success` ? (
                <p>Thank-you!!</p>
              ) : null}
            </div>
            {mode === `submitted` && status === `success` ? (
              <div className="flex items-center flex-shrink-0 mr-6 text-black font-tbold mt-4 md:mb-0">
                <p>
                  Please click here to
                  <a
                    href="/booklet.pdf"
                    target="_blank"
                    className="inline-flex items-center gap-x-1.5 rounded-md bg-mylightorange hover:bg-black px-2.5 py-1.5 text-sm text-black hover:text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Download this e-booklet
                  </a>
                </p>
              </div>
            ) : null}
          </div>
          <div className="border-l-2 border-gray-300 pl-6">
            <div className="max-w-[1400px] overflow-hidden h-full">
              <StaticImage
                src="../images/booklet.jpg"
                alt="Decorative image of booklet"
                objectPosition="0 0"
                className="h-full mx-20 lg:m-0"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-myorange py-16 px-6">
        <div className="mx-auto max-w-3xl text-center lg:text-left">
          <p className="text-white text-3xl lg:text-5xl">
            ... well-being at work is increasingly being recognized as an
            important factor in determining organizational success.
          </p>
        </div>
      </section>
      <section className="py-24">
        <div className="h-80 mx-auto inline">
          <IframeResizer
            title="iframe embed"
            src="https://wallembed.famewall.io/carousel/mwwb?autoplay=1"
            checkOrigin={false}
            autoResize={true}
            scrolling={false}
            width="100%"
            style={{
              border: 0,
              width: `1px`,
              minWidth: `100%`,
            }}
          />
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
